export type MeasureNames<T extends { [key: string]: any }> = {
  [K in keyof T]: T[K] extends number | null ? K : never;
}[keyof T];

export const sumMeasure = <T extends { [key: string]: any }>(
  data: T[],
  name: MeasureNames<T>,
) => data.map((d) => d[name]).reduce((acc, a) => acc + a, 0);

export const getBounds = <T extends { [key: string]: any }>(
  data: T[],
  name: MeasureNames<T>,
) => {
  const values = data.map((d) => d[name]);
  return [Math.min(...values), Math.max(...values)] as const;
};
