import { Box, Button } from "@chakra-ui/react";
import NextImage from "next/image";
import NextLink from "next/link";
import GoogleLogo from "~public/logo/google-logo.png";
import LinkedInLogo from "~public/logo/linkedin-logo.png";
import MicrosoftLogo from "~public/logo/microsoft-logo.png";

export default function LoginProviders() {
  return (
    <Box
      display="flex"
      width="90%"
      flexDirection="row"
      alignSelf="center"
      justifyContent="space-between"
    >
      <Button
        variant="providerButton"
        as={NextLink}
        href={`${process.env.NEXT_PUBLIC_API_URL}/auth/google/authorize`}
        passHref
      >
        <NextImage
          src={GoogleLogo}
          alt="Google Login"
          style={{ width: "50%" }}
        />
      </Button>
      <Button
        variant="providerButton"
        as={NextLink}
        href={`${process.env.NEXT_PUBLIC_API_URL}/auth/microsoft/authorize`}
        passHref
      >
        <NextImage
          src={MicrosoftLogo}
          alt="Microsoft Login"
          style={{ width: "50%" }}
        />
      </Button>
      <Button
        variant="providerButton"
        as={NextLink}
        href={`${process.env.NEXT_PUBLIC_API_URL}/auth/linkedin/authorize`}
        passHref
      >
        <NextImage
          src={LinkedInLogo}
          alt="LinkedIn Login"
          style={{ width: "50%" }}
        />
      </Button>
    </Box>
  );
}
