"use client";

import { Box, Flex } from "@chakra-ui/react";
import { type SiteEntityType } from "@yotta-vision/core/models/site";
import { type ListSitesResult } from "@yotta-vision/functions/rest/sites";
import { GoogleMap } from "components";
import { type MarkerProps } from "components/map/marker";
import GlobalStats from "components/stats/global-stats";
import { filterMap } from "effect/Array";
import * as Option from "effect/Option";
import { useEffect, useState } from "react";
import { useApi } from "~lib/api";
import { isAdmin, useSession } from "~lib/future/contexts/session";
import PagesNav from "~lib/pages-nav";

const markers = filterMap(
  (site: SiteEntityType) =>
    Option.all({
      lat: Option.fromNullable(site.location?.lat),
      lng: Option.fromNullable(site.location?.lng),
      site: Option.some(site),
    }) satisfies Option.Option<MarkerProps>,
);

export default function Home() {
  const [searchedSite, setSearchedSite] = useState("");
  const [debouncedSearchedSite, setDebouncedSearchSite] = useState("");
  const { data: sites } = useApi<ListSitesResult>("/sites");
  const { session } = useSession();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchSite(searchedSite);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchedSite]);

  const resolveAvailableSites = (sites: readonly SiteEntityType[]) =>
    isAdmin(session) ? sites : sites.filter(({ isPublic }) => !!isPublic);

  return (
    <Box bg="bg" height="100vh">
      <Flex
        position="absolute"
        width="90%"
        top="5%"
        left="5%"
        zIndex="overlay"
        direction="column"
        alignItems="flex-start"
        gap="4"
        pointerEvents="none"
      >
        <PagesNav
          data={sites}
          showSearchBar
          onSearch={(value: string) => setSearchedSite(value)}
        />
        <GlobalStats />
      </Flex>
      <GoogleMap
        markers={sites ? markers(resolveAvailableSites(sites)) : []}
        searchedSite={debouncedSearchedSite}
      />
    </Box>
  );
}
