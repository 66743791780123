import { Skeleton } from "@chakra-ui/react";
import { isNumber } from "effect/Predicate";
import { BBox } from "geojson";
import GoogleMap, { LatLngBounds } from "google-maps-react-markers";
import * as React from "react";
import { useEffect } from "react";
import { ClusterProperties, PointFeature } from "supercluster";
import useSupercluster from "use-supercluster";
import { useMapStyles } from "~lib/map-styles";
import { ClusterMarker } from "./cluster-marker";
import { Mappable } from "./mappable";
import { Marker, type MarkerProps } from "./marker";

interface Props {
  markers: readonly MarkerProps[];
  searchedSite: string;
}

function Map({ markers, searchedSite }: Props) {
  const ref = React.useRef<google.maps.Map>();
  const styles = useMapStyles();

  const [bounds, setBounds] = useMapBounds();
  const [zoom, setZoom] = React.useState(defaults.zoom);
  const [center, setCenter] = React.useState(defaults.center);

  const points = React.useMemo(() => markers.map(pointFeature), [markers]);
  const { clusters, supercluster } = useSupercluster({ points, bounds, zoom });

  useEffect(() => {
    if (!!clusters?.length && searchedSite) {
      const searchedPoint = points.find(
        (point) => point.properties?.site?.name === searchedSite,
      );

      if (searchedPoint) {
        const [lng, lat] = searchedPoint.geometry.coordinates;
        setCenter({ lng, lat });
        setZoom(7);
      }
    }
  }, [searchedSite]);

  const onClickCluster = React.useCallback(
    (cluster: (typeof clusters)[number]) => {
      if (supercluster && isNumber(cluster.id)) {
        const expansionZoom = supercluster.getClusterExpansionZoom(cluster.id);
        setZoom(expansionZoom);
      }

      const [lng, lat] = cluster.geometry.coordinates;
      setCenter({ lng, lat });
    },
    [supercluster],
  );

  React.useEffect(() => ref.current?.setZoom(zoom), [zoom]);
  React.useEffect(() => ref.current?.panTo(center), [center]);
  React.useEffect(() => ref.current?.setOptions({ styles }), [styles]);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMap
        apiKey="AIzaSyDYExQexVko9s_8qrO-YapHxwUOs1u6RAw"
        defaultCenter={defaults.center}
        defaultZoom={zoom}
        options={{
          styles,
          maxZoom: 13,
          minZoom: 3,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        }}
        loadingContent={<Skeleton height="100vh" width="100%" />}
        onGoogleApiLoaded={({ map }) => {
          ref.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds(bounds);
        }}
      >
        {clusters.map((cluster) => {
          const [lng, lat] = cluster.geometry.coordinates;

          if (isCluster(cluster)) {
            return (
              <ClusterMarker
                key={cluster.id}
                status={true}
                lat={lat}
                lng={lng}
                count={cluster.properties.point_count}
                onClick={() => onClickCluster(cluster)}
              />
            );
          } else {
            const site = cluster.properties.site;

            return <Marker key={site.siteID} lat={lat} lng={lng} site={site} />;
          }
        })}
      </GoogleMap>
    </div>
  );
}

const isCluster = (a: unknown): a is PointFeature<ClusterProperties> =>
  (a as PointFeature<ClusterProperties>).properties.cluster === true;

const pointFeature = <T extends Mappable>(props: T): PointFeature<T> => ({
  type: "Feature",
  properties: { ...props },
  geometry: {
    type: "Point",
    coordinates: [props.lng, props.lat],
  },
});

const useMapBounds = () => {
  const [mapBounds, setMapBounds_] = React.useState<BBox>([0, 0, 0, 0]);

  const setMapBounds = React.useCallback((bounds: LatLngBounds) => {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    setMapBounds_([
      southWest.lng(),
      southWest.lat(),
      northEast.lng(),
      northEast.lat(),
    ]);
  }, []);

  return [mapBounds, setMapBounds] as const;
};

const defaults = {
  center: {
    lat: 30.210167,
    lng: -97.7479,
  },
  zoom: 5,
};

export default Map;
