import { BoxProps, Flex, Text, chakra, useToken } from "@chakra-ui/react";
import { ListAllAlertsResult } from "@yotta-vision/functions/rest/sites/alerts";
import { FunctionComponent } from "react";
import { BsCircleFill } from "react-icons/bs";
import { useApi } from "~lib/api";

interface Props extends BoxProps {
  amount: number;
}

const SitesCard: FunctionComponent<Props> = ({ amount }) => {
  const { data: alerts } = useApi<ListAllAlertsResult>(`/alerts`);

  const [yottaGreen, yottaRed, yottaOrange, yottaGray] = useToken("colors", [
    "yottaGreenAlert",
    "yottaRedAlert",
    "yottaOrangeAlert",
    "yottaGrayAlert",
  ]);

  const sitesWithAlerts = new Set(
    alerts?.data.alerts
      .filter((alert) => !alert.resolved)
      .map((alert) => alert.siteID),
  ).size;

  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      alignSelf="stretch"
      borderRadius="md"
      boxShadow="0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset"
      backgroundColor="statCard.backgroundColor"
      padding="5"
      gap="4"
    >
      <Text
        fontSize="3xl"
        fontWeight="600"
        lineHeight="normal"
        color="statCard.amount"
      >
        {amount} <chakra.span fontSize="2xl">sites</chakra.span>
      </Text>
      <Flex direction="row" alignItems="flex-start" gap="5">
        <Text display="flex" alignItems="center" gap={1}>
          <BsCircleFill color={yottaGreen} size={14} />
          {Number(amount) - sitesWithAlerts}
        </Text>

        <Text display="flex" alignItems="center" gap={1}>
          <BsCircleFill color={yottaRed} size={14} />
          {sitesWithAlerts}
        </Text>

        <Text display="flex" alignItems="center" gap={1}>
          <BsCircleFill color={yottaOrange} size={14} />
          {0}
        </Text>

        <Text display="flex" alignItems="center" gap={1}>
          <BsCircleFill color={yottaGray} size={14} />
          {0}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SitesCard;
