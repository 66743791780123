import { BoxProps, Flex, Text } from "@chakra-ui/react";
import * as React from "react";

interface StatCardProps extends BoxProps {
  title: string;
  children?: React.ReactNode;
}

const StatCard = ({ title, children }: StatCardProps): JSX.Element => {
  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      alignSelf="stretch"
      borderRadius="md"
      boxShadow="0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset"
      backgroundColor="statCard.backgroundColor"
      padding="5"
    >
      {children}
      <Text fontSize="sm" fontWeight="600">
        {title}
      </Text>
    </Flex>
  );
};

export default StatCard;
